.containerLogin {
    background-color: #F1F1F1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loginCardContainer {
    padding: 100px 90px;
}

.infoCardContainer {
    width: 100%;
    height: 100%;
    color: #F1F1F1;
    padding: 80px;
}
.rowActionInfoCard {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.startIconRow {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}