.titleCard {
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 7px 7px 0 0;
}

.txtCard {
    text-align: center;
    padding: 6px;
    font-size: 15pt;
}