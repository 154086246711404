* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #000;
  background-color: #fff;
}

html, body, #root {
  height: 100%;
}

a {
  text-decoration: none;
}

.bgPrimary {
  background-color: var(--primary);
}
.bgSecondary {
  background-color: var(--secondary);
}
.bgSuccess {
  background-color: var(--success);
}
.bgError {
  background-color: var(--error);
}
.bgBlue {
  background-color: var(--blue);
}
.bgPurple {
  background-color: var(--purple);
}

:root {
  --primary: #091114;
  --secondary: #FFCB23;
  --error: #96031A;
  --success: #00a65a;
  --info: #F4F4F9;
  --purple: #8c00ff;
  --blue: #1500ff;
  --cinze: #7b7b7b;
  --warning: #ede436;
  --white: #fff;
  --opacity: #f0f0f0;
  --black: #000;
  --undraw: #2f2e41;
  --header: #1e2b30;
}
